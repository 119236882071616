// Home.jsx
import React, { useEffect, useRef } from 'react';
import './home.css';
import Spline from '@splinetool/react-spline';
import Landing from '../Landing/Landing';

function Home() {
    const splineRef = useRef(null);

    useEffect(() => {
        if (splineRef.current) {
            splineRef.current.setAttribute("loading", "eager");
        }
    }, []);

    return (
        <div className="main">
            <Landing />
            <Spline className="spline-scene-home" scene="https://prod.spline.design/7a9gb3C6R9fX6YyU/scene.splinecode" />

        </div>
    );
}

export default Home;
