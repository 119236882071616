import React, { useState } from "react";
import './landing.css';
import { initializeApp } from "firebase/app";
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const commonDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "icloud.com",
    "aol.com",
    "mail.com",
    "msn.com",
    "protonmail.com",
    "yandex.com",
    "gmx.com",
    "zoho.com",
    "comcast.net",
    "live.com",
    "me.com",
    "mac.com",
    "qq.com",
    "web.de",
    "sbcglobal.net",
    "ymail.com"
];

function Landing() {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const validateEmailSyntax = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValidEmail(validateEmailSyntax(emailValue));
    };

    const fetchCompanyInfo = async (domain) => {
        if (commonDomains.includes(domain)) return null;

        const response = await fetch(`https://api.hunter.io/v2/domain-search?domain=${domain}&api_key=${process.env.REACT_APP_HUNTER_API_KEY}`);
        const data = await response.json();
        return data || null;
    };

    const verifyDomain = (domain) => {
        return new Promise((resolve, reject) => {
            fetch(`https://dns.google/resolve?name=${domain}&type=MX`)
                .then(response => response.json())
                .then(data => {
                    resolve(data?.Answer?.length > 0);
                })
                .catch(() => reject(false));
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail) return;

        const domain = email.split('@')[1];
        const isDomainValid = await verifyDomain(domain);

        if (!isDomainValid) {
            alert("The email domain is not valid.");
            return;
        }

        const isCustomDomain = !commonDomains.includes(domain);
        let companyInfo = null;

        try {
            const docRef = doc(db, "emails", email);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setIsAlreadySubscribed(true);
                return;
            }

            companyInfo = await fetchCompanyInfo(domain);
            console.log(companyInfo)

            const companyData = {
                email: email,
                domain: domain,
                company: companyInfo?.data.organization || "Unknown",
                company_link: companyInfo?.data.domain || "N/A",
                customDomain: isCustomDomain,
                timestamp: new Date()
            };

            await setDoc(docRef, companyData);

            async function webhook() {
                const url = 'https://chat.googleapis.com/v1/spaces/AAAA7RppPhw/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=avVcO8D4_eU-di9AifjwhCwl5RMa-ZT5GWU3v3nSLak';
                const domainInfo = isCustomDomain ? `Domain: ${companyInfo?.data.domain}` : "No custom domain";
                const companyInfoText = companyInfo ? `\nCompany: ${companyInfo?.data.organization} || ${companyInfo?.data.description}` : "";
                const message = {
                    text: `New email subscription: ${email}${companyInfoText}\n${domainInfo}`
                };
                const res = await fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json; charset=UTF-8" },
                    body: JSON.stringify(message)
                });
                return await res.json();
            }

            webhook().then(res => console.log(res));

            setIsSubscribed(true);
            setEmail("");
        } catch (error) {
            console.error("Error adding email: ", error);
            alert("There was an error. Please try again.");
        }
    };


    return (
        <section id='landing' className="landing">
            <div className="landing-elements">
                <div className="landing-title">Orbilaw</div>
                <div className="landing-middle">Powering Web3 projects with legal excellence</div>
                {isAlreadySubscribed ? (
                    <p className="subscribed-message">You're already subscribed</p>
                ) : isSubscribed ? (
                    <p className="subscribed-message">You've been subscribed</p>
                ) : (

                    <form className="landing-form" onSubmit={handleSubmit}>
                        <div className="invitation">Be the first to know!</div>
                        <div className="landing-input-container">
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter your email"
                                required
                                className="email-input"
                            />
                            <button type="submit" className="landing-button" disabled={!isValidEmail}>
                                Subscribe
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </section>
    );
}

export default Landing;
